<template>
  <footer>
    <ul class="footer-menu" v-if="false">
      <li>
        <a href="#">Контакты</a>
      </li>
      <li>
        <a href="#">Правила пользования</a>
      </li>
      <li>
        <a href="#">Политика конфиденциальности</a>
      </li>
    </ul>
    <div class="footer-content">
      <ul class="footer-socials">
        <li>
          <a href="#" @click.prevent>
            <img width="24" height="24" src="/images/telegram.svg" alt="" />
          </a>
        </li>
        <li>
          <a href="#" @click.prevent>
            <img width="24" height="24" src="/images/youtube.svg" alt="" />
          </a>
        </li>
      </ul>
      <div class="copyright">
        &copy; 2024 Product-express. Доставка продуктов
        {{ city?.name ? `в ${declineCity(city.name)}` : "" }}.
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { useCityStore } from "~/store/cities";

export default defineComponent({
  setup() {
    const store = {
      city: useCityStore(),
    };

    const { city } = storeToRefs(store.city);
    const { declineCity } = useDeclineCity();

    return {
      city,
      declineCity,
    };
  },
});
</script>
