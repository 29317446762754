import { customAxios as axios } from '@/plugins/axios';

import type { Partner } from "~/types/types";

import { useAuthStore } from './auth';
import { useCityStore } from './cities';
import { useErrorStore } from './error';

export const usePartnerStore = defineStore('partner', {
    state: () => {
        return {
            loading: false,

            current: {} as Partner,
            partners: [] as Partner[]
        };
    },
    getters: {
        token: (): string | null => {
            const store = useAuthStore();
            const { token } = storeToRefs(store);

            return token.value.access;
        },
        city: () => {
            const store = useCityStore();
            const { city } = storeToRefs(store);

            return city.value;
        },
        partnerId: (state) => {
            return state.current?.id || null;
        }
    },
    actions: {
        clearData() {
            this.partners = [] as Partner[]

            this.clearPartner()
        },
        clearPartner() {
            const route = useRoute();
            const router = useRouter();

            const query = { ...route.query };
            delete query.partner;
            router.replace({ query });

            this.current = {} as Partner
        },
        setPartner(partner: Partner, withQuery: boolean = true) {
            if (withQuery) {
                const route = useRoute();
                const router = useRouter();

                const query = { ...route.query };
                query.partner = partner?.slug;
                router.replace({ query });
            }

            this.current = partner
        },
        async fetchPartners() {
            this.loading = true;

            if (this.partners?.length === 0) {
                try {
                    const { data: response }: { data: { data: Partner[] } } = await axios.get('/v1/partners', {
                        params: {
                            cityId: this.city.id
                        }
                    });

                    if (response.data) {
                        this.partners = response.data;
                    } else {
                        this.clearData()
                    }
                } catch (error: any) {
                    useErrorStore().setError(error, 'partner');
                    console.error('fetchPartners error', error)
                } finally {
                    this.loading = false;
                }
            }

            return this.partners;
        },
        async fetchPartner(idOrSlug: string) {
            if (!this.current?.id) {
                this.loading = true;
                try {
                    const response: { data: Partner } = await axios.get(`/v1/partners/${idOrSlug}`);

                    if (response.data) {
                        this.setPartner(response.data, false);
                    } else {
                        this.clearData()
                    }
                } catch (error: any) {
                    useErrorStore().setError(error, 'partner');
                    console.error('fetchPartner error', error)
                } finally {
                    this.loading = false;
                }
            }
            return this.current;
        },
    }
});
